@mixin textOverflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

@mixin textOverflowMulti($line: 3, $bg: #fff) {
  position: relative;
  max-height: $line * 1.5em;
  margin-right: -1em;
  padding-right: 1em;
  overflow: hidden;
  line-height: 1.5em;
  text-align: justify;
  &::before {
    position: absolute;
    right: 14px;
    bottom: 0;
    padding: 0 1px;
    background: $bg;
    content: "...";
  }
  &::after {
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
    content: "";
  }
}

// mixins for clearfix
// ------------------------
@mixin clearfix() {
  zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}
