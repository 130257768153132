@import "@utils/utils.scss";
@import "@styles/variables.module.scss";

.activitiesList {
  padding: 0 24px 8px;
  .username {
    color: $text-color;
  }
  .event {
    font-weight: normal;
  }
}
.pageHeaderContent {
  display: flex;
  .avatar {
    flex: 0 1 72px;
    margin-bottom: 8px;
    & > span {
      display: block;
      width: 72px;
      height: 72px;
      border-radius: 72px;
    }
  }
  .content {
    position: relative;
    top: 4px;
    flex: 1 1 auto;
    margin-left: 24px;
    color: $text-color-secondary;
    line-height: 22px;
    .contentTitle {
      margin-bottom: 12px;
      color: $heading-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
.extraContent {
  @include clearfix();

  float: right;
  white-space: nowrap;
  .statItem {
    position: relative;
    display: inline-block;
    padding: 0 32px;
    > p:first-child {
      margin-bottom: 4px;
      color: $text-color-secondary;
      font-size: $font-size-base;
      line-height: 22px;
    }
    > p {
      margin: 0;
      color: $heading-color;
      font-size: 30px;
      line-height: 38px;
      > span {
        color: $text-color-secondary;
        font-size: 20px;
      }
    }
    &::after {
      position: absolute;
      top: 8px;
      right: 0;
      width: 1px;
      height: 40px;
      background-color: $border-color-split;
      content: "";
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
}
.members {
  a {
    display: block;
    height: 24px;
    margin: 12px 0;
    color: $text-color;
    transition: all 0.3s;
    @include textOverflow();
    .member {
      margin-left: 12px;
      font-size: $font-size-base;
      line-height: 24px;
      vertical-align: top;
    }
    &:hover {
      color: $primary-color;
    }
  }
}
.projectList {
  :global {
    .ant-card-meta-description {
      height: 44px;
      overflow: hidden;
      color: $text-color-secondary;
      line-height: 22px;
    }
  }
  .cardTitle {
    font-size: 0;
    a {
      display: inline-block;
      height: 24px;
      margin-left: 12px;
      color: $heading-color;
      font-size: $font-size-base;
      line-height: 24px;
      vertical-align: top;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .projectGrid {
    width: 16.66%;
    .cardTitle {
      height: 100%;
      .excLogo {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .projectItemContent {
    display: flex;
    height: 20px;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    @include textOverflow();
    a {
      display: inline-block;
      flex: 1 1 0;
      color: $text-color-secondary;
      @include textOverflow();
      &:hover {
        color: $primary-color;
      }
    }
    .datetime {
      flex: 0 0 auto;
      float: right;
      color: $disabled-color;
    }
  }
}
.statusData {
  margin-top: 1em;
  :global {
    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
}
.exchangeBalance {
  overflow: auto;
  :global {
    .ant-table-small .ant-table-thead > tr > th {
      color: rgb(0 0 0 / 85%);
      background-color: transparent;
    }
  }
  .exchangeNameContent {
    .datetime {
      font-size: 10.5px;
    }
  }
}
.datetime {
  color: $disabled-color;
}

@media screen and (max-width: $screen-xxl) and (min-width: $screen-xl) {
  .projectList {
    .projectGrid {
      width: 25%;
    }
  }
}

@media screen and (max-width: $screen-xl) and (min-width: $screen-lg) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .extraContent {
    margin-left: -44px;
    .statItem {
      padding: 0 16px;
    }
  }
  .projectList {
    .projectGrid {
      width: 33.33%;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .extraContent {
    float: none;
    margin-right: 0;
    .statItem {
      padding: 0 16px;
      text-align: left;
      &::after {
        display: none;
      }
    }
  }
  .projectList {
    .projectGrid {
      width: 33.33%;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .extraContent {
    margin-left: -16px;
  }
  .projectList {
    .projectGrid {
      width: 50%;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .pageHeaderContent {
    display: block;
    .content {
      margin-left: 0;
    }
  }
  .extraContent {
    .statItem {
      float: none;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .projectList {
    .projectGrid {
      width: 100%;
    }
  }
}
